<template>
  <div>
   

    <b-card
    no-body
  >
  <b-row class="pt-2 pl-2 pr-2 mb-0">  
    <b-col
      cols="12"
      md="6"
      class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
    >
      <h4>Riwayat Perkembangan Kambing</h4>
    </b-col>

  </b-row>
  <hr/>
    <b-table
      responsive
      :fields="fields"
      :items="items"
      ref="refTableMonitoring"
      class="mb-0 mt-0"
    />

  </b-card></div>

  </div>

</template>

<script>
import { BCard, BTable, BRow, BCol, BButton } from 'bootstrap-vue'


export default {
  components: {
    BCard,
    BTable,
    BRow, 
    BCol, 
    BButton,

  },
  data() {
    return {
      items: [],
      fields: [
        'id',
        'status',
        'kategori',
        'status_keluar',
        'device',
        'kandang',
        'berat',
        'panjang',
        'suhu',
        'foto',
      ],
    }
  },
  mounted(){
    // this http get request to get data from backend

    
    const mapServiceHistoryToModel = ({
      id,
      animal,
      animal_status,
      animal_type,
      animal_status_out,
      device,
      pen,
      body_weight,
      body_length,
      body_temperature,
      photo,
    }) => ({
      id:id,
      status:animal_status,
      kategori:animal_type,
      status_keluar:animal_status_out,
      device:device,
      kandang:pen,
      berat:body_weight,
      panjang:body_length,
      suhu:body_temperature,
      foto:photo,
    });

    this.$http.get(`/monitorings/${this.$route.params.id }/history`).then(response => {
      const data = response.data.data
      this.items = data.map(mapServiceHistoryToModel)
    })
  
  },
}
</script>